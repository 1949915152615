import React from 'react';
import Text from "@/components/ui/Text";
type IHeadingProp = {
    text:string,
    hint?:string,
    gradient?:boolean,
    className?:string
}
const Heading = (props:IHeadingProp) => {
    const {text,hint,gradient=false,className=""} = props;
    const gradientColor = "bg-gradient-to-r from-[#4041A1] to-[#A4E2EB] bg-clip-text text-transparent"
    return (
        <div className="w-full flex flex-col justify-center items-center">
            {hint && <Text as="span" variant="small" className="uppercase !font-bold text-extended_neutral-N600">{hint}</Text>}
            <Text as="h2" variant="h2" className={gradient ? gradientColor : "text-extended_neutral-N600"}>{text}</Text>
        </div>
    );
};

export default Heading;