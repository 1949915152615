import React from 'react';
import {PlayCircle} from "@/components/icons";

const UserCommentCardSkeleton = () => {
    return (
        <div
            className="relative group w-[295px] h-[405px] rounded-page  overflow-hidden cursor-pointer bg-white animate-pulse">
            <div className="flex flex-col gap-3 h-full p-4">
                <div className="h-[29px] w-[50px] rounded-full bg-slate-500/10 animate-pulse"></div>
                <div className="flex-1 flex justify-center items-center"><PlayCircle size={100}
                                                                                     className="transition-all duration-500 text-slate-500/10 animate-pulse"/>
                </div>
                <div className="bg-slate-500/10 animate-pulse w-full h-[92px] rounded-lg"></div>
            </div>
        </div>
    );
};

export default UserCommentCardSkeleton;