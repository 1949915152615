import React from 'react';
import UserCommentWrapper from "@/components/landing/Main/UserComments/UserCommentWrapper";
import Image from "next/image";
import Chip from "@/components/ui/Chip";
import {PlayCircle} from "@/components/icons";
import ThumbnailCard from "@/components/shareds/Thumbnails/ThumbnailCard";
import UserCommentCardSkeleton from "@/components/landing/Main/UserComments/UserCommentCardSkeleton";
type Props = {
    id:string;
}
const UserCommentContent = (props:Props) => {
    return (
        <UserCommentWrapper id={props.id} >
            <div className="flex gap-4 items-start overflow-hidden">
                <UserCommentCardSkeleton />
                <UserCommentCardSkeleton />
                <UserCommentCardSkeleton />
                <UserCommentCardSkeleton />
            </div>
        </UserCommentWrapper>
    );
};

export default UserCommentContent;