"use client";
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import UserCommentCard from "@/components/landing/Main/UserComments/UserCommentCard";
import UserCommentWrapper from "@/components/landing/Main/UserComments/UserCommentWrapper";
import { env } from "@/utils/env";
import { useGetLandingSiteSettingsService } from "@/services/Shareds/Settings/useGetLandingSiteSettings";
import UserCommentContentSkeleton from "@/components/landing/Main/UserComments/UserCommentContentSkeleton";
type Props = {
  id: string;
};
type IUserComment = {
  image_url: string;
  video: string;
  seller_name: string;
  avatar_url: string;
  seller_rating: number;
};
const UserCommentContent = (props: Props) => {
  const { data, isLoading } = useGetLandingSiteSettingsService();
  const user_comments = data?.responseData?.data?.find(
    (x) => x.description == "landing_user_comments"
  )?.data?.json_data as IUserComment[];
  if (isLoading) return <UserCommentContentSkeleton id="" />;
  return (
    <UserCommentWrapper id={props.id}>
      <Swiper spaceBetween={16} slidesPerView="auto">
        {user_comments?.map((comment, index) => (
          <SwiperSlide className="!w-fit" key={index}>
            <UserCommentCard
              avatar={comment.avatar_url}
              title={comment.seller_name}
              image={comment.image_url}
              videoId={comment.video}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </UserCommentWrapper>
  );
};

export default UserCommentContent;
