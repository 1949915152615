'use client'
import React from 'react';
import NovaImage from "@/components/ui/NovaImage";
type Props = {
    image:string,
    big?:boolean,
    className?:string;
}
const NovacentBannerCard = (props:Props) => {
    const {image,big=false,className=""} = props;
    const customClass = big ? "aspect-square mobile:aspect-auto mobile:h-[535px]"  : "aspect-square mobile:aspect-auto mobile:h-[320px] laptop:h-[261px]";
    return (
        <div className={`flex flex-shrink-0 overflow-hidden rounded-page ${customClass} ${className}`}>
            <figure className="w-full h-full relative">
                <NovaImage src={image} alt="" width={650} height={650} className="w-full h-full object-cover object-left" quality={100} />
            </figure>
        </div>
    );
};

export default NovacentBannerCard;