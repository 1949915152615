"use client"
import React from 'react';

const PopularProductCardSkeleton = () => {
    return (
        <div className={`bg-extended_neutral-N0 flex flex-col rounded-page items-start px-component-x-lg py-component-y-lg gap-3 flex-1 laptop:max-w-[332px] desktop:max-w-[595px]`}>
            <div className="w-full h-[353px] desktop:h-[375px] relative bg-slate-500/10 rounded-lg animate-pulse">
            </div>
            <div className="flex flex-col items-start gap-3 w-full">
                <div className="bg-slate-500/10 w-[105px] h-[19px] rounded-lg animate-pulse"></div>
                <div className="bg-slate-500/10 w-full h-[52px] rounded-lg animate-pulse"></div>
            </div>
            <div className="bg-slate-500/10 w-full h-3 rounded-lg animate-pulse"></div>
        </div>
    );
};

export default PopularProductCardSkeleton;