import React from "react";
import Text from "@/components/ui/Text";
import IconComponent from "@/components/shareds/IconComponent";

type Props = {
  icon: string;
  title: string;
  hint: string;
};

const ServicesCard = (props: Props) => {
  const { icon, title, hint } = props;
  return (
    <div className="bg-white rounded-page flex px-component-x py-component-y items-center gap-3 text-extended_neutral-N600">
      {icon && (
        <span className="w-12 h-12 rounded-component bg-extended_neutral-N100 flex justify-center items-center gap-2 p-3">
          <IconComponent iconName={icon} />
        </span>
      )}
      <div className="flex flex-col justify-center items-start gap-1 flex-1">
        <Text as="h6" variant="h6">
          {title}
        </Text>
        <Text as="p" variant="caption">
          {hint}
        </Text>
      </div>
    </div>
  );
};

export default ServicesCard;
