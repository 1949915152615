import React from 'react';
import clsx from "clsx";
import LazyImage from "@/components/ui/LazyImage";
import {titleToAvatar} from "@/utils/stringUtils";
type Variants = "default" | "border"
type Size = "small" | "medium" | "large"

interface Props {
    size?: Size,
    variant?: Variants,
    title?: string,
    image?: string,
}
const ThumbnailAvatar = (props:Props) => {
    const {variant="default",size="small",title,image} = props;
    return (
        <div className={clsx("rounded-full overflow-hidden relative" ,
            {"" : variant == "default"},
            {"border-2 p-[2px] border-extended_green-G500" : variant == "border"},
            {"size-8" : size == "small"},
            {"size-10" : size == "medium"},
            {"size-[60px]" : size == "large"}
        )}>
            {(title  && !image) && <span className="bg-white w-full h-full flex justify-center items-center rounded-full font-black">{titleToAvatar(title)}</span>}
            {image && <LazyImage src={image} alt="" width={80} height={80} className="w-full h-full object-cover rounded-full object-center bg-white" />}
        </div>
    );
};

export default ThumbnailAvatar;