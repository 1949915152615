import React, {Suspense} from 'react';
import Image from "next/image";
import Text from "@/components/ui/Text";
import LazyImage from "@/components/ui/LazyImage";
type ISpecialProductCard = {
    image: string,
    title: string,
    desc: string
}
const SpecialProductCard = (props:ISpecialProductCard) => {
    const {image,title,desc} = props;
    return (
            <div
                className="flex col-span-1 px-component-x-lg py-component-y-lg flex-col items-start gap-3 bg-extended_neutral-N0 rounded-page">
                <figure className="w-full aspect-square relative"><LazyImage src={image} alt="" width={565} height={565}
                                                                             className="w-full h-full object-contain"/>
                </figure>
                <div className="flex flex-col gap-1 items-start">
                    <Text as="h6" variant="h6" className="line-clamp-1">{title}</Text>
                    <Text as="p" variant="caption" className="line-clamp-2">{desc}</Text>
                </div>
            </div>
    );
};

export default SpecialProductCard;