import { showToast } from "./showToast";

export function titleToAvatar(title: string) {
  const titles = title.split(" ");
  let newTitle = "";
  titles?.map((t) => (newTitle += t.slice(0, 1)));
  return newTitle;
}
export function titleToSlug(url: string): string {
  const turkishChars: Record<string, string> = {
    ç: "c",
    ğ: "g",
    ı: "i",
    ö: "o",
    ş: "s",
    ü: "u",
  };
  url = url?.replace(/ç|ğ|ı|ö|ş|ü/gi, function (matched) {
    return turkishChars[matched.toLowerCase()];
  });
  return url
    ?.toLowerCase()
    .replace(/[\s\W-]+/g, " ")
    .trim()
    .split(" ")
    .join("-");
}
export function extractTitleAndId(
  url: string,
  split: string = "p"
): {
  title: string | null;
  id: string | null;
  match: any | null;
} {
  const matches = url.split(`${split}-`);

  if (matches) {
    return { match: matches, title: matches.slice(0,(matches?.length- 1))?.join(` ${split} `), id: matches[matches?.length - 1] };
  }

  return { match: null, title: null, id: null };
}

export function slugToNormal(slug: string): string {
  return slug
    .split("-")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export const handleCopy = (text: string) => {
  navigator.clipboard.writeText(text).then(() => {
    showToast({
      title: "Kopyalandı",
      hint: "Başarılı bir şekilde kopyalandı",
      id: "copy-success",
      type: "success",
    });
  });
};
