export const CompanyRoutes = {
  product: {
    detail: (slug: string, id: string) => `/product/${slug}-p-${id}`,
  },
  seller: (slug: string, id: string) => `/seller/${slug}-s-${id}`,
  category: (slug: string, id: string) => `/category/${slug}-c-${id}`,
  brand: (slug: string, id: string) => `/brand/${slug}-b-${id}`,
  search: (keyword: string) => `/search/${keyword}`,
  basket: () => "/basket",
  order: () => "/order",
  orderDetail: (id: number) => `/order/detail/${id}`,
  novacent: () => "/novacent",
  settings: () => "/settings",
  favorites: () => "/favorites",
  sharedLists: () => "/shared-list",
  favoritesSeller: () => "/favorites-seller",
  messages: () => "/messages",
  accounting: () => "/accounting",
  message: (id: number) => `/messages?id=${id}`,
  myProducts: () => "/my-products",
  print: {
    cargo: (id: string) => `/cargo-print?id=${id}`,
    einvoice: (id: string) => `/einvoice-print?id=${id}`,
  },
};
