import(/* webpackMode: "eager" */ "/var/www/vhosts/novadan.com.tr/httpdocs/components/landing/Brands/Brands.tsx");
import(/* webpackMode: "eager" */ "/var/www/vhosts/novadan.com.tr/httpdocs/components/landing/Main/Faq/FaqContent.tsx");
import(/* webpackMode: "eager" */ "/var/www/vhosts/novadan.com.tr/httpdocs/components/landing/Main/Faq/FaqContentWrapper.tsx");
import(/* webpackMode: "eager" */ "/var/www/vhosts/novadan.com.tr/httpdocs/components/landing/Main/NovacentBanner/NovacentBannerCardSkeleton.tsx");
import(/* webpackMode: "eager" */ "/var/www/vhosts/novadan.com.tr/httpdocs/components/landing/Main/NovacentBanner/NovacentBannerContent.tsx");
import(/* webpackMode: "eager" */ "/var/www/vhosts/novadan.com.tr/httpdocs/components/landing/Main/PopularProducts/PopularProductCard.tsx");
import(/* webpackMode: "eager" */ "/var/www/vhosts/novadan.com.tr/httpdocs/components/landing/Main/SpecialProducts/SpecialProductList.tsx");
import(/* webpackMode: "eager" */ "/var/www/vhosts/novadan.com.tr/httpdocs/components/landing/Main/UserComments/UserCommentContent.tsx");
import(/* webpackMode: "eager" */ "/var/www/vhosts/novadan.com.tr/httpdocs/components/landing/Main/UserComments/UserCommentWrapper.tsx");
import(/* webpackMode: "eager" */ "/var/www/vhosts/novadan.com.tr/httpdocs/components/landing/Main/WhyUs/WhyUsContent.tsx");
import(/* webpackMode: "eager" */ "/var/www/vhosts/novadan.com.tr/httpdocs/components/shareds/Banner/BannerWrapper.tsx");
import(/* webpackMode: "eager" */ "/var/www/vhosts/novadan.com.tr/httpdocs/components/shareds/Banner/BannerWrapperSkeleton.tsx");
import(/* webpackMode: "eager" */ "/var/www/vhosts/novadan.com.tr/httpdocs/components/shareds/Faq/FaqItemCardSkeleton.tsx");
import(/* webpackMode: "eager" */ "/var/www/vhosts/novadan.com.tr/httpdocs/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js")