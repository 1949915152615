import * as Icons from "@/components/icons";
import React from "react";

const IconComponent = ({ iconName }: { iconName: string }) => {
  const Icon = Icons[iconName as keyof typeof Icons];
  if (!Icon) {
    return null;
  }
  return <Icon />;
};

export default IconComponent;
