"use client";
import React from "react";
import FaqItemCard from "@/components/shareds/Faq/FaqItemCard";
import Heading from "@/components/landing/Main/Heading";
import { FAQS } from "@/types/Faqs";
import FaqItemCardSkeleton from "@/components/shareds/Faq/FaqItemCardSkeleton";
import FaqContentWrapper from "@/components/landing/Main/Faq/FaqContentWrapper";
import { useGetLandingSiteSettingsService } from "@/services/Shareds/Settings/useGetLandingSiteSettings";
import { FaqContentSkeleton } from "@/components/landing/Main/Faq/FaqContentSkeleton";
type Props = {
  id: string;
};

type IFaqs = {
  title: string;
  description: string;
};

const FaqContent = (props: Props) => {
  const { data, isLoading } = useGetLandingSiteSettingsService();
  const faqs = data?.responseData?.data?.find(
    (item) => item.description === "landing_faqs"
  )?.data?.json_data as IFaqs[];
  if (isLoading) return <FaqContentSkeleton id="" />;
  return (
    <FaqContentWrapper id={props.id}>
      {faqs?.map((item, key) => (
        <FaqItemCard key={key} title={item.title} hint={item.description} />
      ))}
    </FaqContentWrapper>
  );
};

export default FaqContent;
