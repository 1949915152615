"use client";
import React, { useState } from "react";
import Image from "next/image";
import { Loading } from "@/components/icons";
import { ImageProps } from "next/dist/shared/lib/get-img-props";
import { motion } from "framer-motion";

interface Props extends ImageProps {
  outherClassName?: string;
  fallback?: string;
}

const NovaImage = (props: Props) => {
  const {
    fallback = "/images/nophoto1.jpg",
    loading,
    onLoad,
    outherClassName = "",
    className = "",
    src,
    ...otherProps
  } = props;
  const [fallBacksrc, setFallbackSrc] = useState(src);
  const [loaded, setLoaded] = useState(true);
  return (
    <motion.div className={`relative ${outherClassName}`}>
      <Image
        loading="lazy"
        onLoad={() => setLoaded(false)}
        className={`select-none object-contain ${className}`}
        onError={() => setFallbackSrc(fallback)}
        src={fallBacksrc}
        {...otherProps}
      />
      {loaded && (
        <div className="w-full h-full flex justify-center items-center absolute top-0 left-0 bg-slate-400/10 border-0">
          <Loading className="animate-spin" size={40} />
        </div>
      )}
    </motion.div>
  );
};

export default NovaImage;
