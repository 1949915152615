'use client'
import React from 'react';
import ReactDOM from "react-dom";
import ModalVideo from "react-modal-video";

type ModalVideoProps = {
    isOpen: boolean;
    onClose: () => void;
    videoId: string
}

const ModalVideoContent = (props:ModalVideoProps) => {
    const {isOpen,onClose,videoId} = props;
    return ReactDOM.createPortal(<ModalVideo onClose={onClose} channel="youtube"
                                             youtube={{ mute: 0, autoplay: 1,controls:0,showinfo:0}} isOpen={isOpen}
                                             videoId={videoId}  />,document.body)
};

export default ModalVideoContent;