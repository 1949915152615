'use client'
import React, {useEffect, useRef, useState} from 'react';
import Text from "@/components/ui/Text";
import {PlusNormal} from "@/components/icons";
import clsx from "clsx";

const FaqItemCard = () => {
    return (
        <details open={false} className="bg-white p-component-x rounded-page w-full select-none cursor-pointer">
            <summary className="list-none h-9 flex justify-between items-center gap-x-component-x-lg ">
                <div  className="w-full h-9 rounded-lg bg-slate-500/10 animate-pulse">
                </div>
                <div className=" size-6 rounded-lg bg-slate-500/10 animate-pulse"></div>
            </summary>
        </details>
    );
};

export default FaqItemCard;