import React from "react";
import Heading from "@/components/landing/Main/Heading";
import NovacentBannerCardSkeleton from "@/components/landing/Main/NovacentBanner/NovacentBannerCardSkeleton";

const NovacentBannerContentSkeleton = () => {
  return (
    <section className="flex flex-col justify-center items-center w-full gap-y-component-y-lg">
      <Heading
        text="Hediye Dolu Alışveriş Keyfi!"
        hint="İHTİYAÇLARINIZI EN UYGUN FİYAT İLE KARŞILAYIN"
        gradient
      />
      <div className="flex flex-col laptop:flex-row justify-center gap-component-y-lg w-full">
        <div className="flex-1">
          <NovacentBannerCardSkeleton big />
        </div>
        <div className="flex-1 grid grid-cols-1 mobile:grid-cols-2 gap-component-y-lg">
          <NovacentBannerCardSkeleton className="col-span-1" />
          <NovacentBannerCardSkeleton className="col-span-1" />
          <NovacentBannerCardSkeleton className="col-span-1" />
          <NovacentBannerCardSkeleton className="col-span-1" />
        </div>
      </div>
    </section>
  );
};

export default NovacentBannerContentSkeleton;
