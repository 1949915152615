'use client'
import React from 'react';
import FaqItemCard from "@/components/shareds/Faq/FaqItemCard";
import Heading from "@/components/landing/Main/Heading";
import {FAQS} from "@/types/Faqs";
import FaqItemCardSkeleton from "@/components/shareds/Faq/FaqItemCardSkeleton";
type Props = {
    id:string,
    children:React.ReactNode
}
const FaqContentWrapper = (props:Props) => {
    return (
        <section id={props.id} className="w-full flex flex-col gap-component-y-lg">
            <Heading text="Merak Ettiğiniz Her Şey" hint="İHTİYAÇLARINIZI EN UYGUN FİYAT İLE KARŞILAYIN" gradient/>
            <div className="grid grid-cols-1 mobile:grid-cols-2 gap-component-x-lg items-start">
                {props.children}
            </div>
        </section>
    );
};

export default FaqContentWrapper;