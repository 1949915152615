"use client";
import React from "react";
import Heading from "@/components/landing/Main/Heading";
import NovacentBannerCard from "@/components/landing/Main/NovacentBanner/NovacentBannerCard";
import { useGetLandingSiteSettingsService } from "@/services/Shareds/Settings/useGetLandingSiteSettings";
import NovacentBannerSkeletonWrapper from "@/components/landing/Main/NovacentBanner/NovacentBannerSkeletonWrapper";

type Props = {
  id: string;
};
const NovacentBannerContent = (props: Props) => {
  const { data, isLoading } = useGetLandingSiteSettingsService();
  if (isLoading) return <NovacentBannerSkeletonWrapper />;
  return (
    <section
      className="flex flex-col justify-center items-center w-full gap-y-component-y-lg"
      id={props.id}
    >
      <Heading
        text="Hediye Dolu Alışveriş Keyfi!"
        hint="İHTİYAÇLARINIZI EN UYGUN FİYAT İLE KARŞILAYIN"
        gradient
      />
      <div className="flex flex-col laptop:flex-row justify-center gap-component-y-lg w-full">
        <div className="flex-1">
          <NovacentBannerCard
            image={
              data?.responseData?.data?.find(
                (banner) => banner.description == "novacent_banner_big"
              )?.image_url || ""
            }
            big
          />
        </div>
        <div className="flex-1 grid grid-cols-1 mobile:grid-cols-2 gap-component-y-lg">
          <NovacentBannerCard
            image={
              data?.responseData?.data?.find(
                (banner) => banner.description == "novacent_banner_small_1"
              )?.image_url || ""
            }
            className="col-span-1"
          />
          <NovacentBannerCard
            image={
              data?.responseData?.data?.find(
                (banner) => banner.description == "novacent_banner_small_2"
              )?.image_url || ""
            }
            className="col-span-1"
          />
          <NovacentBannerCard
            image={
              data?.responseData?.data?.find(
                (banner) => banner.description == "novacent_banner_small_3"
              )?.image_url || ""
            }
            className="col-span-1"
          />
          <NovacentBannerCard
            image={
              data?.responseData?.data?.find(
                (banner) => banner.description == "novacent_banner_small_4"
              )?.image_url || ""
            }
            className="col-span-1"
          />
        </div>
      </div>
    </section>
  );
};

export default NovacentBannerContent;
