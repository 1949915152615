"use client";
import React from "react";
import SpecialProductCard from "@/components/landing/Main/SpecialProducts/SpecialProductCard";

import SpecialProductCardSkeleton from "@/components/landing/Main/SpecialProducts/SpecialProductCardSkeleton";
import { useGetLandingCampaigns } from "@/services/Landing/Campaigns/useGetLandingCampaigns";
export const PRODUCT_LIST = [
  {
    title: "Bebelac",
    image: "/images/bebelac.png",
    desc: "Bebelac 1 Bebek Sütü 0-6 Ay 800 gr",
  },
  {
    title: "Aptamil",
    image: "/images/aptamil.png",
    desc: "Aptamil Milupa 1 Bebek Sütü Kutu 900 gr",
  },
  {
    title: "Corsa",
    image: "/images/corsa.png",
    desc: "Corsa 365 Mineral Blend 90 Kapsül",
  },
  {
    title: "Elidor",
    image: "/images/elidor.jpeg",
    desc: "Elidor 7/24 Şekillendirici Saç Bakım Kremi",
  },
  {
    title: "Ostwint",
    image: "/images/ostwint.png",
    desc: "Ostwint Professional Hassas Ciltler İçin Tüy",
  },
  {
    title: "Supradyn",
    image: "/images/supradyn.png",
    desc: "Supradyn Energy Focus 30 Tablet",
  },
];

const SpecialProductList = () => {
  const { data, isValidating } = useGetLandingCampaigns();
  return (
    <div className="flex-1 grid grid-cols-2 mobile:grid-cols-3 gap-component-x-md desktop:gap-component-x-lg w-full">
      {isValidating
        ? Array(6)
            .fill(6)
            .map((x, key) => <SpecialProductCardSkeleton key={key} />)
        : data?.responseData?.current
            ?.flatMap((campaign) =>
              campaign?.sub_campaign?.map((subCampaign) => (
                <SpecialProductCard
                  key={subCampaign?.id}
                  title={
                    subCampaign?.product?.product_brand?.brand?.short_title
                  }
                  desc={subCampaign?.product?.title}
                  image={
                    subCampaign?.product?.product_selected_image?.image_url
                  }
                />
              ))
            )
            ?.slice(0, 6)}
    </div>
  );
};

export default SpecialProductList;
