"use client";
import React from "react";
import NovaImage from "@/components/ui/NovaImage";
type Props = {
  big?: boolean;
  className?: string;
};
const NovacentBannerCardSkeleton = (props: Props) => {
  const { big = false, className = "" } = props;
  const customClass = big
    ? "aspect-square mobile:aspect-auto mobile:h-[535px]"
    : "aspect-square mobile:aspect-auto mobile:h-[320px] laptop:h-[261px]";
  return (
    <div
      className={`flex flex-shrink-0 overflow-hidden rounded-page ${customClass} ${className}`}
    >
      <div className="w-full h-full relative">
        <div className="w-full h-full object-cover object-left bg-slate-500/10"></div>
      </div>
    </div>
  );
};

export default NovacentBannerCardSkeleton;
