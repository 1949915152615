import React from 'react';
const SpecialProductCardSkeleton = () => {
    return (
        <div
            className="flex col-span-1 px-component-x-lg py-component-y-lg flex-col items-start gap-3 bg-extended_neutral-N0 rounded-page">
            <div className="w-full aspect-square relative bg-slate-500/10 rounded-lg animate-pulse ">
            </div>
            <div className="flex flex-col gap-1 items-start w-full">
                <div className="bg-slate-500/10 w-full h-[36px] rounded-lg animate-pulse "></div>
                <div className="bg-slate-500/10 w-full h-[28px] rounded-lg animate-pulse "></div>
            </div>
        </div>
    );
};

export default SpecialProductCardSkeleton;