'use client'
import React from 'react';
import Heading from "@/components/landing/Main/Heading";
import 'swiper/css';
type Props = {
    id:string;
    children:React.ReactNode
}
const UserCommentContent = (props:Props) => {
    return (
        <section id={props.id} className="flex flex-col justify-center items-center w-full gap-y-component-y-lg">
            <Heading text="Bizi Neden Tercih Ediyorlar?" hint="İHTİYAÇLARINIZI EN UYGUN FİYAT İLE KARŞILAYIN" gradient />
            <div className="w-full">
                {props.children}
            </div>
        </section>
    );
};

export default UserCommentContent;