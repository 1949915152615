export const FAQS = [
  {
    title: "Novadan’a nasıl üye olabilirim.?",
    hint: "Unlock the full power of Acme! Gain expertise and insights from top organizations through guided tutorials, boosting productivity, enhancing security, and enabling seamless collaboration.",
  },
  {
    title: "Novadan’a nasıl üye olabilirim.?",
    hint: "Unlock the full power of Acme! Gain expertise and insights from top organizations through guided tutorials, boosting productivity, enhancing security, and enabling seamless collaboration.",
  },
  {
    title: "Üye olabilmek için ayrıca bir ücret ödemem gerekiyor mu.?",
    hint: "Unlock the full power of Acme! Gain expertise and insights from top organizations through guided tutorials, boosting productivity, enhancing security, and enabling seamless collaboration.",
  },
  {
    title: "Üye olabilmek için ayrıca bir ücret ödemem gerekiyor mu.?",
    hint: "Unlock the full power of Acme! Gain expertise and insights from top organizations through guided tutorials, boosting productivity, enhancing security, and enabling seamless collaboration.",
  },
  {
    title: "Ürünlerimi satışa sunmak istiyorum, hemen satabilir miyim.?",
    hint: "Unlock the full power of Acme! Gain expertise and insights from top organizations through guided tutorials, boosting productivity, enhancing security, and enabling seamless collaboration.",
  },
  {
    title: "Ürünlerimi satışa sunmak istiyorum, hemen satabilir miyim.?",
    hint: "Unlock the full power of Acme! Gain expertise and insights from top organizations through guided tutorials, boosting productivity, enhancing security, and enabling seamless collaboration.",
  },
  {
    title: "Kendi mağazama özel kargo kampanyası oluşturmak istiyorum.?",
    hint: "Unlock the full power of Acme! Gain expertise and insights from top organizations through guided tutorials, boosting productivity, enhancing security, and enabling seamless collaboration.",
  },
  {
    title: "Kendi mağazama özel kargo kampanyası oluşturmak istiyorum.?",
    hint: "Unlock the full power of Acme! Gain expertise and insights from top organizations through guided tutorials, boosting productivity, enhancing security, and enabling seamless collaboration.",
  },
  {
    title: "Üyeliğimi tamamen sistemden silmek istiyorum.",
    hint: "Unlock the full power of Acme! Gain expertise and insights from top organizations through guided tutorials, boosting productivity, enhancing security, and enabling seamless collaboration.",
  },
  {
    title: "Üyeliğimi tamamen sistemden silmek istiyorum.",
    hint: "Unlock the full power of Acme! Gain expertise and insights from top organizations through guided tutorials, boosting productivity, enhancing security, and enabling seamless collaboration.",
  },
];
