import React from "react";

const ServicesCard = () => {
  return (
    <div className="bg-white rounded-page flex px-component-x py-component-y items-center gap-3 text-extended_neutral-N600">
      <span className="w-12 h-12 rounded-component bg-extended_neutral-N100 flex justify-center items-center gap-2 p-3 animate-pulse"></span>
      <div className="flex flex-col justify-center items-start gap-1 flex-1">
        <div className="w-full h-[18px] bg-slate-500/10 animate-pulse rounded-lg"></div>
        <div className="w-full h-[28px] bg-slate-500/10 animate-pulse rounded-lg"></div>
      </div>
    </div>
  );
};

export default ServicesCard;
