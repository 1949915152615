'use client'
import React, {useEffect, useRef, useState} from 'react';
import Text from "@/components/ui/Text";
import {PlusNormal} from "@/components/icons";
import clsx from "clsx";
import {AnimatePresence, motion} from 'framer-motion';

type Props= {
    title:string,
    hint:string
}

const FaqItemCard = (props:Props) => {
    const {title,hint} = props;
    const [open,setOpen] = useState(false);
    const onToggle = (event:any) => {
        event.preventDefault();
        setOpen(!open);
    };

    return (
        <details open={open} className="bg-white p-component-x rounded-page w-full select-none cursor-pointer">
            <summary onClick={onToggle} className="list-none h-9 flex justify-between items-center gap-x-component-x-lg">
                <Text as="summary" variant="h6" className="">
                    {title}
                </Text>
                <PlusNormal size={24} className={clsx("flex-shrink-0",open ? "rotate-45" : "")} />
            </summary>
            <AnimatePresence>
                {open &&
                    <motion.div initial={{opacity: 0}} animate={{opacity: 1}} exit={{opacity: 0}}
                                transition={{duration: .5}}>
                        <Text as="p" variant="paragraph" className="pt-2">{hint}</Text>
                    </motion.div>}
            </AnimatePresence>
        </details>
    );
};

export default FaqItemCard;