import React from "react";
import Heading from "@/components/landing/Main/Heading";
import ServicesCardSkeleton from "@/components/landing/Main/WhyUs/ServicesCardSkeleton";

const WhyUsContentSkeleton = () => {
  return (
    <section className="w-full flex flex-col gap-y-component-y-lg">
      <Heading
        text="Kaliteli ve Güvenilir Çözümler"
        gradient
        hint="İHTİYAÇLARINIZI EN UYGUN FİYAT İLE KARŞILAYIN"
      />
      <div className="grid grid-cols-1 mobile:grid-cols-2 gap-component-y">
        {Array(6)
          .fill(6)
          ?.map((item, index) => (
            <ServicesCardSkeleton key={index} />
          ))}
      </div>
    </section>
  );
};

export default WhyUsContentSkeleton;
