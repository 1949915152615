import {FAQS} from "@/types/Faqs";
import React from "react";
import FaqContentWrapper from "@/components/landing/Main/Faq/FaqContentWrapper";
import FaqItemCardSkeleton from "@/components/shareds/Faq/FaqItemCardSkeleton";
type Props = {
    id:string,
}
export const FaqContentSkeleton  = (props : Props) => {
    return  <FaqContentWrapper id={props.id}>
        {FAQS.map((item,key) => <FaqItemCardSkeleton key={key}  />)}
    </FaqContentWrapper>
}