"use client";
import React from "react";
import Heading from "@/components/landing/Main/Heading";
import ServicesCard from "@/components/landing/Main/WhyUs/ServicesCard";
import { useGetLandingSiteSettingsService } from "@/services/Shareds/Settings/useGetLandingSiteSettings";
import WhyUsContentSkeleton from "@/components/landing/Main/WhyUs/WhyUsContentSkeleton";
type Props = {
  id: string;
};

type JsonModel = {
  icon: string;
  title: string;
  description: string;
};

const WhyUsContent = (props: Props) => {
  const { data, isLoading } = useGetLandingSiteSettingsService();
  const servicesList = data?.responseData?.data?.find(
    (x) => x.description == "landing_page_services"
  )?.data?.json_data as JsonModel[];
  if (isLoading) return <WhyUsContentSkeleton />;
  return (
    <section
      className="w-full flex flex-col gap-y-component-y-lg"
      id={props.id}
    >
      <Heading
        text="Kaliteli ve Güvenilir Çözümler"
        gradient
        hint="İHTİYAÇLARINIZI EN UYGUN FİYAT İLE KARŞILAYIN"
      />
      <div className="grid grid-cols-1 mobile:grid-cols-2 gap-component-y">
        {servicesList?.map((item, index) => (
          <ServicesCard
            key={index}
            icon={item.icon}
            title={item.title}
            hint={item.description}
          />
        ))}
      </div>
    </section>
  );
};

export default WhyUsContent;
