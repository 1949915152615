"use client";
import { useEffect, useState } from "react";

export default function useMedia(width = 1024, type: "min" | "max" = "max") {
  const [status, setStatus] = useState(false);
  useEffect(() => {
    const matchMedia = window.matchMedia(`(${type}-width:${width}px)`);
    setStatus(matchMedia.matches);
    const onChangeHandle = (e: any) => {
      setStatus(e.matches);
    };
    matchMedia.addEventListener("change", onChangeHandle);
  }, [type, width]);
  return status;
}
