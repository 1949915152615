"use client";
import React from "react";
import Image from "next/image";
import { PlayCircle } from "@/components/icons";
import Chip from "@/components/ui/Chip";
import ThumbnailCard from "@/components/shareds/Thumbnails/ThumbnailCard";
import "/node_modules/react-modal-video/css/modal-video.css";
import ModalVideoContent from "@/components/landing/Main/ModalVideoContent";
import LazyImage from "@/components/ui/LazyImage";
type Props = {
  image: string;
  avatar?: string;
  videoId?: string;
  title: string;
};

const UserCommentCard = (props: Props) => {
  const [open, setOpen] = React.useState(false);
  const { image, videoId = "UsQEQSO3czc", avatar, title } = props;
  return (
    <>
      <div
        className="relative group w-[295px] h-[405px] rounded-page  overflow-hidden cursor-pointer"
        onClick={() => setOpen(true)}
      >
        <LazyImage
          src={image}
          alt=""
          width={500}
          height={500}
          quality={100}
          className="absolute -z-10 w-full h-full object-cover  transition-all duration-700 group-hover:scale-[1.1] bg-gradient-to-t from-neutral-900 to-neutral-400"
        />
        <div className="flex flex-col gap-3 h-full p-4">
          <Chip
            variant="bordered"
            color="danger"
            size="small"
            className="bg-white w-fit"
          >
            Video
          </Chip>
          <div className="flex-1 flex justify-center items-center">
            <PlayCircle
              size={100}
              className="transition-all duration-500 text-white  mix-blend-soft-light"
            />
          </div>
          <ThumbnailCard title={title} image={avatar} rating={4} />
        </div>
      </div>
      {open && (
        <ModalVideoContent
          isOpen={open}
          videoId={videoId}
          onClose={() => setOpen(false)}
        />
      )}
    </>
  );
};

export default UserCommentCard;
