import React from "react";
import useSWR from "swr";
import { fetcher } from "@/utils/fetcher";
import Base from "@/models/base/Base";
import { Campaign } from "@/models/Campaign";
import { AxiosError } from "axios";
import { SubCampaign } from "@/models/SubCampaign";

type Props = {
  key?: string;
  data?: any;
};

const BASE_URL = "/v1/campaign/landing-page-campaigns";
export const useGetLandingCampaigns = (props?: Props) => {
  const { data, isLoading, error, isValidating, mutate } = useSWR<
    Base<{ special: SubCampaign; current: Campaign[] }>,
    AxiosError
  >(
    BASE_URL,
    () =>
      fetcher<Base<{ special: SubCampaign; current: Campaign[] }>>(
        BASE_URL,
        "GET"
      ),
    { revalidateOnFocus: false }
  );
  return { data, isLoading, error, isValidating, mutate };
};
