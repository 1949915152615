import React from 'react';
import ThumbnailAvatar from "@/components/shareds/Thumbnails/ThumbnailAvatar";
import Text from "@/components/ui/Text";
import {Rating, Star} from "@smastrom/react-rating";
import '@smastrom/react-rating/style.css'

type Props = {
    title:string;
    rating:number;
    image?:string;
}

const ThumbnailCard = (props:Props) => {
    const {image,rating,title} = props;
    return (
        <div className="flex items-center px-component-x-lg py-component-y-lg rounded-page bg-extended_neutral-N700/50 backdrop-blur-[10px] gap-4">
            <ThumbnailAvatar variant="border" size="large" image={image} title={title} />
            <div className="flex flex-col gap-1 justify-start items-start">
                <Text as="h5" variant="h5" className="text-extended_neutral-N0">{title}</Text>
                <Rating value={rating} style={{maxWidth:100}} readOnly itemStyles={{itemShapes:Star,activeFillColor: '#F05A29', inactiveFillColor: '#ECEDF7'}} />
            </div>
        </div>
    );
};

export default ThumbnailCard;