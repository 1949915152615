"use client";
import React from "react";
import Chip from "@/components/ui/Chip";
import Text from "@/components/ui/Text";
import LazyImage from "@/components/ui/LazyImage";
import PopularProductCardSkeleton from "@/components/landing/Main/PopularProducts/PopularProductCardSkeleton";
import { useGetLandingCampaigns } from "@/services/Landing/Campaigns/useGetLandingCampaigns";

type IPopularProductCard = {
  className?: string;
};
const PopularProductCard = (props: IPopularProductCard) => {
  const { data, isValidating } = useGetLandingCampaigns();
  const { className = "" } = props;
  return isValidating ? (
    <PopularProductCardSkeleton />
  ) : (
    <div
      className={`bg-extended_neutral-N0 flex flex-col rounded-page items-start px-component-x-lg py-component-y-lg gap-3 ${className}`}
    >
      <figure className="w-full h-[353px] desktop:h-[375px] relative">
        <LazyImage
          src={
            data?.responseData?.special?.product?.product_selected_image
              ?.image_url || ""
          }
          alt=""
          width={565}
          height={565}
          className={`w-full h-full object-contain`}
        />
      </figure>
      <div className="flex flex-col items-start gap-3">
        <Chip size="small" variant="bordered" color="danger">
          Haftanın Ürünü
        </Chip>
        <Text as="h3" variant="h3">
          {data?.responseData?.special?.product?.title}
        </Text>
      </div>
      <Text as="small" variant="small">
        Bu ürün stoklarla sınırlı.
      </Text>
    </div>
  );
};

export default PopularProductCard;
